import Modal from "react-modal";
import Button from "./Button";
function LiveLocationModal(props) {
  const displayMap = (zone) => {
    switch (zone) {
      case "banff":
        return {
          __html:
            '<iframe src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.17662&lng=-115.56368&zoom=13&routes=9505,9506,9507,12635,12633,10031" width="100%" height="100%"></iframe>',
        };
      case "canmore":
        return {
          __html:
            '<iframe src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.075230&lng=-115.345900&zoom=13&routes=33008,33007,76745" width="100%" height="100%"></iframe>',
        };
      case "lake-louise":
        return {
          __html:
            '<iframe src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.290765&lng=-115.873735&zoom=9&routes=12635" width="100%" height="100%"></iframe>',
        };
      case "morraine-lake":
        return {
          __html:
            '<iframe src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.290565&lng=-115.873735&zoom=10&routes=14222" width="100%" height="100%"></iframe>',
        };
      default:
        return {
          __html:
            '<iframe src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.17662&lng=-115.56368&zoom=13&routes=9505,9506,9507,12635,12633" width="100%" height="100%"></iframe>',
        };
    }
  };

  const { openModal, closeModal, modalIsOpen, trilliumStopZone } = props;
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Live Bus Locations"
        style={{
          overlay: {
            backgroundColor: "#1a1a1a",
          },
          content: {
            inset: "5px",
            padding: "0",
          },
        }}
      >
        <div className="fixed right-3 text-black">
          <Button clickHandler={closeModal}>Close</Button>
        </div>
        <div
          style={{ width: `100%`, height: `100%` }}
          dangerouslySetInnerHTML={displayMap(trilliumStopZone)}
        />
      </Modal>
    </div>
  );
}

export default LiveLocationModal;
