import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import RouteHeading from "./ui-components/RouteHeading";
import LoadingIndicator from "./ui-components/LoadingIndicator";
import Button from "./ui-components/Button";

function ArrivalTimes(props) {
  const { stopInfo, quickScheduleElement } = props;

  const [arrivalTimes, setArrivalTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const timer = useRef();

  function scrollToQuickSchedule() {
    quickScheduleElement.current.scrollIntoView({
      behavior: "smooth",
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      if (stopInfo) {
        console.log("Stop ID", stopInfo.stop_id);
        fetch(
          `https://dashboard.roamtransit.com/dashboard-feeds/arrival-times/realtime-gtfs-arrival-times.php?stop_id=${stopInfo.stop_id}`
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setArrivalTimes(data);
            setLoading(false);
          });
      }
    };

    timer.current = setInterval(() => {
      fetchData();
    }, 60000);

    fetchData();

    return () => {
      clearInterval(timer.current);
    };
  }, [stopInfo]);

  return (
    <div className="bg-white p-5 rounded-lg shadow-md mt-5">
      <h1 className="text-center text-2xl font-bold">
        {stopInfo && stopInfo.stop_name}
      </h1>
      <h2 className="text-center text-md font-bold">
        {" "}
        {arrivalTimes.length > 0 && `Upcoming Departure Times`}
      </h2>

      {loading && <LoadingIndicator />}

      {loading === false && arrivalTimes.length === 0 && (
        <>
          <p className="text-center">No arrival information available.</p>
          <p className="text-center">
            <Button clickHandler={scrollToQuickSchedule}>View Schedule</Button>
          </p>
        </>
      )}

      {arrivalTimes.length > 0 && (
        <div className="pt-5">
          {arrivalTimes.map((route) => {
            return (
              <>
                <RouteHeading
                  backgroundColor={route.routeColour}
                  routeName={route.routeName}
                />
                <div className="flex pt-1 pb-2">
                  {route.arrivalTimes.map((item) => {
                    return (
                      <div className="flex-1 flex flex-col items-center">
                        <div className="text-5xl">{item}</div>
                        <div>mins</div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ArrivalTimes;
