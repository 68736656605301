import Button from "./ui-components/Button";
function LiveLocations(props) {
  const { openModal, trilliumStopZone } = props;

  const displayMap = (zone) => {
    switch (zone) {
      case "banff":
        return {
          __html:
            '<iframe class="mt-5 pointer-events-none" src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.17662&lng=-115.56368&zoom=12&routes=9505,9506,9507,12635,12633,10031" width="100%" height="300"></iframe>',
        };
      case "canmore":
        return {
          __html:
            '<iframe class="mt-5 pointer-events-none" src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.075230&lng=-115.345900&zoom=12&routes=9505,33008,33007,76745" width="100%" height="300"></iframe>',
        };
      case "lake-louise":
        return {
          __html:
            '<iframe class="mt-5 pointer-events-none" src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.290765&lng=-115.873735&zoom=9&routes=12635" width="100%" height="300"></iframe>',
        };
      case "morraine-lake":
        return {
          __html:
            '<iframe class="mt-5 pointer-events-none" src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.290565&lng=-115.873735&zoom=9&routes=14222" width="100%" height="300"></iframe>',
        };
      default:
        return {
          __html:
            '<iframe class="mt-5 pointer-events-none" src="https://dashboard.roamtransit.com/dashboard-feeds/live-bus/index.html?lat=51.17662&lng=-115.56368&zoom=12&routes=9505,9506,9507,12635,12633" width="100%" height="300"></iframe>',
        };
    }
  };

  return (
    <div className="bg-white p-5 rounded-lg shadow-md mt-5">
      <h1 className="text-xl font-bold text-center">Live Bus Locations</h1>
      <div dangerouslySetInnerHTML={displayMap(trilliumStopZone)} />
      <div className="text-center mt-3">
        <Button clickHandler={openModal}>Full Screen</Button>
      </div>
    </div>
  );
}

export default LiveLocations;
