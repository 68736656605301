import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

function LoadingIndicator(){
    return (
        <div className="text-center pt-5">
            <FontAwesomeIcon icon={faCircleNotch} size="4x" spin color="orange"/>
        </div>
    )

}

export default LoadingIndicator