import Layout from "./components/Layout/Layout.js";
import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Layout />}></Route>
        <Route exact path="/stop-info/:stopId" element={<Layout />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
