function Button(props){
    const { clickHandler } = props
    return(
        <button onClick={clickHandler} className="mt-2 bg-yellow-500 text-white font-bold py-2 px-4 rounded">
            { props.children }
        </button>
    )
}

export default Button

