import { useEffect, useState } from "react";
import { useParams } from "react-router";
import React from "react";
import RouteHeading from "./ui-components/RouteHeading";
import LoadingIndicator from "./ui-components/LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function hexToLighterHSL(H, factor) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1) + factor;

  return "hsl(" + h + "," + s + "%," + l + "%)";
}

function getScheduleForRoute(stopId, routeId) {
  return fetch(
    `https://dashboard.roamtransit.com/GTFSv2/populate_stop_times_new.php?route=${routeId}&stop_id=${stopId}`
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

function QuickSchedule(props) {
  const { trilliumStopId, quickScheduleElement } = props;

  const [routeSchedules, setRouteSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (trilliumStopId !== -1) {
      fetch(
        `https://dashboard.roamtransit.com/GTFSv2/populate_routes_new.php?stop_id=${trilliumStopId}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          let tempArray = data.map(async (route) => {
            return {
              routeId: route.route_id,
              routeName: route.route_long_name,
              routeColor: route.route_color,
              schedule: await getScheduleForRoute(
                trilliumStopId,
                route.route_id
              ),
            };
          });

          Promise.all(tempArray).then((values) => {
            setRouteSchedules(values);
            setLoading(false);
          });
        });
    } else if (trilliumStopId === null) {
      setError(true);
      setLoading(false);
    }
  }, [trilliumStopId]);

  return (
    <div
      className="bg-white p-5 rounded-lg shadow-md mt-5"
      ref={quickScheduleElement}
    >
      <h1 className="text-xl font-bold text-center">Stop Schedules</h1>
      {loading && <LoadingIndicator />}
      {!loading && routeSchedules.length === 0 && trilliumStopId !== -1 && (
        <p className="text-center">
          <FontAwesomeIcon icon={faExclamationTriangle} color="red" /> Error
          Loading Stop Schedules
        </p>
      )}
      {routeSchedules.map((item) => {
        return (
          item.schedule.length > 0 && (
            <div className="pt-5">
              <RouteHeading
                routeName={item.routeName}
                backgroundColor={item.routeColor}
              />
              <div className="grid grid-cols-4 md:grid-cols-4 pt-3 text-sm md:text-base gap-0">
                {item.schedule.map((scheduleTime, index) => {
                  return (
                    <div
                      className="text-white py-1 text-center border-b border-white"
                      style={{
                        backgroundColor:
                          index % 2 === 0
                            ? `#${item.routeColor}`
                            : hexToLighterHSL(`#${item.routeColor}`, 15),
                      }}
                    >
                      {scheduleTime.stop_time}
                    </div>
                  );
                })}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}

export default QuickSchedule;
