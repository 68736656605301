function RouteHeading(props) {
  const { backgroundColor, routeName } = props;

  return (
    <div
      className="rounded-md text-center text-white p-1"
      style={{ backgroundColor: `#${backgroundColor}` }}
    >
      {routeName}
    </div>
  );
}

export default RouteHeading;
