import logo from "../../resources/images/roam-logo.png";
import { useEffect, useRef, useState } from "react";
import ArrivalTimes from "../ArrivalTimes";
import LiveLocations from "../LiveLocations";
import LiveLocationsModal from "../ui-components/LiveLocationsModal";
import QuickSchedule from "../QuickSchedule";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import ReactGA from "react-ga4";

ReactGA.initialize("G-EHYQD59H2S");

function Layout() {
  const { stopId } = useParams();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [stopInfo, setStopInfo] = useState(null);
  const [trilliumStopId, setTrilliumStopId] = useState(-1);
  const [trilliumStopZone, setTrilliumStopZone] = useState(-1);
  const quickScheduleElement = useRef();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    fetch(
      `https://dashboard.roamtransit.com/stop-feeds/translate-stops.php?doublemap_stop_id=${stopId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTrilliumStopId(data.stop_id);
        setTrilliumStopZone(data.stop_region);
      });

    fetch(
      `https://dashboard.roamtransit.com/GTFSv2/populate_stop_info.php?stop_id=${trilliumStopId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStopInfo(data);
      });
  }, [trilliumStopId, stopId]);

  return (
    <>
      <div className="wrapper max-w-screen-sm m-auto p-5">
        <header className="header">
          <img src={logo} className="App-logo m-auto" alt="Roam Transit Logo" />
        </header>
        <section className="arrival-times">
          <ArrivalTimes
            stopInfo={stopInfo}
            trilliumStopZone={trilliumStopZone}
            quickScheduleElement={quickScheduleElement}
          />
          <LiveLocations
            trilliumStopZone={trilliumStopZone}
            openModal={openModal}
          />
          <QuickSchedule
            trilliumStopId={trilliumStopId}
            quickScheduleElement={quickScheduleElement}
          />
        </section>
        <footer className="text-white pt-5 text-center">
          <div className="flex flex-wrap justify-center gap-x-4">
            <div>
              <a href="tel:+1.403.762.0606">
                <FontAwesomeIcon
                  icon={faPhone}
                  color="orange"
                  className="mr-2"
                />
                +1.403.762.0606
              </a>
            </div>
            <div>
              <a href="mailto:info@roamtransit.com">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  color="orange"
                  className="mr-2"
                />
                info@roamtransit.com
              </a>
            </div>
          </div>
          <div className="mt-5 flex justify-center gap-5">
            <div>
              <a href="https://twitter.com/roamtransit" target="_blank">
                <FontAwesomeIcon size="2x" icon={faTwitter} color="white" />
              </a>
            </div>
            <div>
              <a
                href="https://www.facebook.com/roampublictransit/"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faFacebook} color="white" />
              </a>
            </div>
          </div>
        </footer>
      </div>
      <LiveLocationsModal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        trilliumStopZone={trilliumStopZone}
      />
    </>
  );
}

export default Layout;
